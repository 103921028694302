import { MutableRefObject, useEffect, useRef } from 'react';

interface UseIframeHeightOptions {
    padding?: number;
}

export const useIframeHeight = (options?: UseIframeHeightOptions): void => {
    const heightRef: MutableRefObject<any> = useRef();

    useEffect(() => {
        window.setInterval(() => {
            const height: string = `${document.body.clientHeight + (options?.padding || 0)}px`;
            if (window.parent && heightRef.current !== height) {
                heightRef.current = height;
                window.parent.postMessage(['setHeight', height], '*');
            }
        }, 200);
    }, [options]);
};
