import { useAppContext } from '../../state';
import Progress from '../ProgressBar';
import QuestionForm from '../QuestionESGForm';
import { H2 } from '../ui/Typography';
import { useHasRiskQuestions } from './hooks';
import { Container } from './styles';

function QuestionnaireESG() {
    const { state } = useAppContext();
    useHasRiskQuestions();

    return (
        <Container>
            <H2>Your attitude to sustainable investing</H2>

            <Progress currentStep={state?.currentESGQuestion} totalSteps={state?.esgQuestions.length} />

            <QuestionForm />
        </Container>
    );
}

export default QuestionnaireESG;
