import { createContext, ReactNode, useContext, useReducer } from 'react';

/**
 * State management solution based on this article:
 * https://ionic.io/blog/a-state-management-pattern-for-ionic-react-with-react-hooks
 */
interface State {
    questions: object[];
    esgQuestions: object[];
    currentQuestion: number;
    currentESGQuestion: number;
    answers: object[];
    esgAnswers: object[];
    riskRating?: number;
    riskRatingDescription?: string;
    esgRating?: number;
    esgRatingDescription?: string;
    pdfUrl: string;
    steps: number;
    currentStep: number;
}

interface ContextProviderProps {
    children?: ReactNode;
}

export const AppContext = createContext<any>({} as State);

export const useAppContext = () => useContext(AppContext);

export const SET_QUESTIONS = 'SET_QUESTIONS';
export const SET_ESG_QUESTIONS = 'SET_ESG_QUESTIONS';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const SET_CURRENT_ESG_QUESTION = 'SET_CURRENT_ESG_QUESTION';
export const UPDATE_ANSWERS = 'UPDATE_ANSWERS';
export const UPDATE_ESG_ANSWERS = 'UPDATE_ESG_ANSWERS';
export const SET_RISK_RATING = 'SET_RISK_RATING';
export const SET_RISK_RATING_DESCRIPTION = 'SET_RISK_RATING_DESCRIPTION';
export const SET_ESG_RATING = 'SET_ESG_RATING';
export const SET_ESG_RATING_DESCRIPTION = 'SET_ESG_RATING_DESCRIPTION';
export const RESET_FORM = 'RESET_FORM';
export const RESET_ESG_FORM = 'RESET_ESG_FORM';

export const CREATE_PDF = 'CREATE_PDF';
export const RESET_CREATE_PDF = 'RESET_CREATE_PDF';

export const reducer = (state: any, action: any) => {
    switch (action.type) {
        case SET_QUESTIONS: {
            if (!state.questions) {
                return state;
            }
            return {
                ...state,
                questions: action.questions,
            };
        }
        case SET_ESG_QUESTIONS: {
            if (!state.esgQuestions) {
                return state;
            }
            return {
                ...state,
                esgQuestions: action.questions,
            };
        }
        case SET_CURRENT_QUESTION: {
            return {
                ...state,
                currentQuestion: action.currentQuestion,
            };
        }
        case SET_CURRENT_ESG_QUESTION: {
            return {
                ...state,
                currentESGQuestion: action.currentQuestion,
            };
        }

        case UPDATE_ANSWERS: {
            return {
                ...state,
                answers: [...state.answers, action.answer],
            };
        }
        case UPDATE_ESG_ANSWERS: {
            return {
                ...state,
                esgAnswers: [...state.esgAnswers, action.answer],
            };
        }
        case SET_RISK_RATING: {
            return {
                ...state,
                riskRating: action.riskRating,
            };
        }
        case SET_RISK_RATING_DESCRIPTION: {
            return {
                ...state,
                riskRatingDescription: action.description,
            };
        }
        case SET_ESG_RATING: {
            return {
                ...state,
                esgRating: action.esgRatingResults,
            };
        }

        case SET_ESG_RATING_DESCRIPTION: {
            return {
                ...state,
                esgRatingDescription: action.esgRatingDescription,
            };
        }
        case RESET_FORM: {
            return {
                ...state,
                currentQuestion: 1,
                answers: [],
                riskRating: undefined,
            };
        }
        case RESET_ESG_FORM: {
            return {
                ...state,
                currentESGQuestion: 1,
                esgAnswers: [],
                esgRating: undefined,
            };
        }

        case CREATE_PDF: {
            return {
                ...state,
                pdfUrl: action.url,
            };
        }

        case RESET_CREATE_PDF: {
            return {
                ...state,
                pdfUrl: '',
            };
        }

        default: {
            return state;
        }
    }
};

const logger = (reducer: Function) => {
    const reducerWithLogger = (state: object, action: any) => {
        // console.log('%cPrevious State: color: #9E9E9E; font-weight: 700;', state);
        // console.log('%cAction:', 'color: #00A7F7; font-weight: 700;', action);
        console.log('%cNext State:', 'color: #47B04B; font-weight: 700;', reducer(state, action));
        return reducer(state, action);
    };

    return reducerWithLogger;
};

const loggerReducer = logger(reducer);

export const initialState: State = {
    questions: [],
    steps: 4,
    esgQuestions: [],
    currentQuestion: 1,
    currentESGQuestion: 1,
    currentStep: 1,
    answers: [],
    esgAnswers: [],
    pdfUrl: '',
    riskRating: undefined,
    riskRatingDescription: undefined,
    esgRating: undefined,
    esgRatingDescription: undefined,
};

export function AppContextProvider({ children }: ContextProviderProps) {
    const fullInitialState = {
        ...initialState,
    };

    let [state, dispatch] = useReducer(loggerReducer, fullInitialState);
    let value = { state, dispatch };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export const AppContextConsumer = AppContext.Consumer;
