import { AnswerStyled, Input, Label } from './styles';

export function Answer({ question, id, register, text, selectedOption, index }: any) {
    return (
        <>
            <AnswerStyled>
                <Input type="radio" id={`question${id}`} {...register('esganswer', { required: true })} value={text} />

                <Label htmlFor={`question${id}`}>{text}</Label>
            </AnswerStyled>
            {index === 0 && selectedOption === 'Yes' && question && (
                <>
                    <Label htmlFor={`question-${id}`}>{question}</Label>

                    <div className="esg-yes-container">
                        <div className="percentage-box">%</div>
                        <input
                            className="esg-yes-question"
                            type="number"
                            placeholder="Enter Value"
                            {...register('esgvalue', {
                                required: true,
                                min: 1,
                                max: 100,
                            })}
                        />
                    </div>
                </>
            )}
        </>
    );
}

export default Answer;
