/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const generateRiskResultPDF = /* GraphQL */ `
    mutation GenerateRiskResultPDF($input: RiskResultPDFInput) {
        generateRiskResultPDF(input: $input) {
            url
        }
    }
`;
export const calculateRisk = /* GraphQL */ `
    mutation CalculateRisk($responses: [Response]) {
        calculateRisk(responses: $responses) {
            rating
        }
    }
`;
