import { Container as RBContainer } from 'react-bootstrap';
import styled from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';

export const Container = styled(RBContainer)`
    padding: 18px 0;
    ${breakpointUp.lg`
        width: 1192px;
    `}
`;

export const StyledHeader = styled.header`
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    ${breakpointUp.lg`
    `}

    img {
        padding-left: 16px;
        min-height: 32px;
        height: 40px !important;
    }
`;
