import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { logAnalyticsEvent } from 'services/analytics';
import { RESET_FORM, useAppContext } from '../../state';

export const useStartQuestionnaire = () => {
    const { dispatch } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        logAnalyticsEvent({ action: `Take our questionnaire`, label: `Display` });
    }, []);

    const { reset } = useForm();
    const startQuestionnaire = useCallback(() => {
        logAnalyticsEvent({ action: `Take our questionnaire`, label: `I'm ready to start button` });
        reset();
        dispatch({
            type: RESET_FORM,
        });
        navigate('/questionnaire');
    }, [dispatch, navigate, reset]);

    return {
        startQuestionnaire,
    };
};
