import { Routes, Route } from 'react-router-dom';
import QuestionnaireStart from '../QuestionnaireStart';
import Questionnaire from '../Questionnaire';
import Results from '../Results';
import Error from '../Error';
import SustainableInvesting from 'components/SustainableInvesting';
import QuestionnaireESG from 'components/QuestionnaireESG';

import { useIframeHeight } from './hooks';

function App() {
    useIframeHeight({ padding: 50 });
    return (
        <Routes>
            <Route path="/" element={<QuestionnaireStart />} />

            <Route path="questionnaire" element={<Questionnaire />} />

            <Route path="/sustainable-investing" element={<SustainableInvesting />} />

            <Route path="/questionnaire-esg" element={<QuestionnaireESG />} />

            <Route path="/results" element={<Results />} />

            <Route path="/error" element={<Error />} />
        </Routes>
    );
}

export default App;
