import styled from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';
import uiAlert from '../ui/Alert';
import { Card as RBCard, Container as RBContainer, Row as RBRow } from 'react-bootstrap';

// export const Container = styled.div`
//     width: 90%;
//     max-width: 800px;
//     margin: 0 auto;
//     padding: 0 15px;
// `;

export const Container = styled(RBContainer)`
    ${breakpointUp.lg`
        width: 756px;
    `}
`;

export const Row = styled(RBRow)`
    .col-mobile-bottom-margin {
        margin-bottom: 32px;

        ${breakpointUp.lg`
            margin-bottom: 0;
        `}
    }
`;

export const Alert = styled(uiAlert)`
    margin: 20px 0 30px 0;
`;

export const Navigation = styled.div`
    ${breakpointUp.md`
        display: flex;
        justify-content: flex-end;
    `}
`;

export const Gutter = styled.div`
    margin-bottom: 32px;
`;

export const CardIcon = styled.img`
    width: 48px;
    height: 48px;
`;
export const Card = styled(RBCard)`
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.cardBorder};
`;

export const CardTitle = styled(Card.Title)`
    margin: 0;
`;

export const CardText = styled(Card.Text)`
    margin: 0;
`;
