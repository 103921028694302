import { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Container, Row } from './styles';
import { H2, P } from 'components/ui/Typography';
import Button from 'components/ui/Button';
import QuestionIcon from '../../assets/img/icon-question.svg';

import { SustainableInvestingContent } from 'data/content';
import { useHasQuestions } from './hooks';
import { Link } from 'react-router-dom';
import { Accordion, AccordionGroup, AccordionItem, AccordionLabel } from 'components/ui/Accordion';
import { logAnalyticsEvent } from 'services/analytics';

function SustainableInvesting() {
    const [isOpenOne, setIsOpenOne] = useState(false);
    const [isOpenTwo, setIsOpenTwo] = useState(false);
    // Check that first check of questions have been done -
    useHasQuestions();

    useEffect(() => {
        logAnalyticsEvent({ action: 'Assessing your sustainable preferences', label: `Display` });
    }, []);

    const handleToggleAccordionOne = () => {
        if (!isOpenOne) {
            logAnalyticsEvent({
                action: 'Assessing your sustainable preferences',
                label: 'Sustainability investing accordion',
            });
        }
        setIsOpenOne(!isOpenOne);
    };

    const handleToggleAccordionTwo = () => {
        if (!isOpenTwo) {
            logAnalyticsEvent({
                action: 'Assessing your sustainable preferences',
                label: 'Sustainability performance accordion',
            });
        }
        setIsOpenTwo(!isOpenTwo);
    };

    return (
        <Container>
            <Row>
                <Col>
                    <H2>{SustainableInvestingContent.title}</H2>
                    <P>{SustainableInvestingContent.textOne}</P>
                    <ul className="sustainable-list">
                        {SustainableInvestingContent.list.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </Col>
            </Row>
            <Row>
                <AccordionGroup>
                    <Accordion value="first" onClick={handleToggleAccordionOne}>
                        <AccordionItem slot="header" color="light">
                            <AccordionLabel>
                                <div className="accordion-title">
                                    <img src={QuestionIcon} alt="question icon" />
                                    {SustainableInvestingContent.accordionTitleOne}
                                </div>
                            </AccordionLabel>
                        </AccordionItem>
                        <div slot="content">
                            {SustainableInvestingContent.accordionContentOne ?? (
                                <P>{SustainableInvestingContent.accordionContentOne}</P>
                            )}
                            <ul>
                                {SustainableInvestingContent.accordionListOne.map((item, index) => (
                                    <li className="mb-4" key={index}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Accordion>
                </AccordionGroup>

                <AccordionGroup>
                    <Accordion value="first" onClick={handleToggleAccordionTwo}>
                        <AccordionItem slot="header" color="light">
                            <AccordionLabel>
                                <div className="accordion-title">
                                    <img src={QuestionIcon} alt="question icon" />
                                    {SustainableInvestingContent.accordionTitleTwo}
                                </div>
                            </AccordionLabel>
                        </AccordionItem>
                        <div slot="content">
                            <P>{SustainableInvestingContent.accordionContentTwo}</P>
                            <ul>
                                {SustainableInvestingContent.accordionListTwo.map((item, index) => (
                                    <li className="mb-4" key={index}>
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </Accordion>
                </AccordionGroup>
            </Row>
            <Row>
                <Col>
                    <P className="pb-4">
                        <strong>{SustainableInvestingContent.question}</strong>
                    </P>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="d-lg-flex justify-content-between">
                        <Link to="/results">
                            <Button
                                onClick={() =>
                                    logAnalyticsEvent({
                                        action: 'Assessing your sustainable preferences',
                                        label: 'Skip this section button',
                                    })
                                }
                                secondary
                            >
                                {SustainableInvestingContent.buttonOne}
                            </Button>
                        </Link>

                        <Link to="/questionnaire-esg">
                            <Button
                                onClick={() =>
                                    logAnalyticsEvent({
                                        action: 'Assessing your sustainable preferences',
                                        label: 'Go to questions button',
                                    })
                                }
                            >
                                {SustainableInvestingContent.buttonTwo}
                            </Button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default SustainableInvesting;
