import { StyledHeader, Container } from './styles';
import SLLogo from '../../assets/img/sl-logo.svg';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <StyledHeader>
            <Container>
                <Link to="/">
                    <img src={SLLogo} alt="Standard Life Logo" />
                </Link>
            </Container>
        </StyledHeader>
    );
}
