import { Answers, Fieldset, Legend, Navigation } from './styles';
import Button from '../ui/Button';
import { P } from '../ui/Typography';
import { Accordion, AccordionGroup, AccordionItem, AccordionLabel } from '../ui/Accordion';
import { useQuestionForm } from './hooks';
import LoadingState from './LoadingState';
import QuestionIcon from '../../assets/img/icon-question.svg';
import Answer from './Answer';
import { logAnalyticsEvent } from 'services/analytics';

function QuestionESGForm() {
    const { handleSubmit, register, formState, question, buttonLabel, onSubmit, watch } = useQuestionForm();

    if (!question) return <LoadingState />;

    const { id: questionId, text, answers, accordionData } = question;
    const selectedOption = watch('esganswer');

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Fieldset id={`question${questionId}Answer`}>
                <Legend>{text}</Legend>

                <AccordionGroup id={accordionData.id}>
                    <Accordion value="first">
                        <AccordionItem slot="header" color="light">
                            <AccordionLabel>
                                <div className="accordion-title">
                                    <img src={QuestionIcon} alt="question icon" />
                                    {accordionData.accordionTitle}
                                </div>
                            </AccordionLabel>
                        </AccordionItem>
                        <div slot="content">
                            <P>{accordionData.accordionContent}</P>
                            {accordionData.accordionContentTwo && <P>{accordionData.accordionContentTwo}</P>}

                            {accordionData.accordionList && (
                                <ul className="esg-list">
                                    {accordionData.accordionList.map((item: any, index: number) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </Accordion>
                </AccordionGroup>

                <Answers className="esg-answers">
                    {answers.map(({ id, text, question }: any, index: number) => (
                        <Answer
                            key={index}
                            index={index}
                            id={id}
                            selectedOption={selectedOption}
                            text={text}
                            question={question}
                            register={register}
                        />
                    ))}
                </Answers>
            </Fieldset>

            <Navigation>
                <Button
                    onClick={() => {
                        buttonLabel === 'Next question'
                            ? logAnalyticsEvent({
                                  action: `Assessing your sustainable preferences Q${questionId}`,
                                  label: `Next question button`,
                              })
                            : logAnalyticsEvent({
                                  action: `Assessing your sustainable preferences Q${questionId}`,
                                  label: `Submit button`,
                              });
                    }}
                    type="submit"
                    disabled={!formState.isValid}
                >
                    {buttonLabel}
                </Button>
            </Navigation>
        </form>
    );
}
export default QuestionESGForm;
