const theme = {
    colors: {
        primaryText: '#051a3f',
        primaryAction: '#147CB3',
        primaryActionHover: '#116998',
        primaryActionActive: '#4396C2',
        secondaryAction: '#fffff',
        secondaryActionHover: '#EFF6FA',
        secondaryActionActive: '#4396C2',
        paleBlue: '#eff6fa',
        bodyText: '#656a76',
        progressComplete: '#008616',
        progressCurrent: '#4396c2',
        cardBorder: '#d9dbdd',
        borderGrey: '#A8AAB1',
        loadingSkeleton: 'rgba(217, 219, 221, 0.5)',
        black: '#000000',
        white: '#ffffff',
        red: '#BE1D13',
        grey: '#3c4253',
        greyLight: '#f5f6f7',
        blue: '#0a2f73',
    },
};

export default theme;
