import IconDownload from '../assets/img/icon-download.svg';

export const questionnaireStart = {
    title: 'Risk Analyser Questionnaire',
    subtitle: 'Check your attitude to risk',

    contentOne: `Check how much risk you might be comfortable taking with your investments with our risk questionnaire by making the decision to invest, you're giving your money more chance to potentially grow than if you're just saving it. How much risk you take can affect your potential growth so it's important to understand how much you're willing and able to take.`,

    contentTwo: `Our questionnaire helps you understand your attitude towards risk. It asks several key investment-related questions designed to assess your experience with different types of investments and your comfort level with taking risks.`,

    contentThree: `Each response is assigned a score, which is then combined to provide you with an overall risk rating - there are five levels of risk ratings.`,

    riskRatings: {
        title: 'What are the risk ratings?',
        listTitle: 'Click each level to learn more',
        radioGroup: [
            {
                levelNumber: '1',
                level: 'Low',
                eventLabel: 'Low',
                eventAction: 'What are the risk ratings',
            },
            {
                levelNumber: '2',
                level: 'Low-Medium',
                eventLabel: 'Low-Medium',
                eventAction: 'What are the risk ratings',
            },
            {
                levelNumber: '3',
                level: 'Medium',
                eventLabel: 'Medium',
                eventAction: 'What are the risk ratings',
            },
            {
                levelNumber: '4',
                level: 'Medium-High',
                eventLabel: 'Medium-High',
                eventAction: 'What are the risk ratings',
            },
            {
                levelNumber: '5',
                level: 'High',
                eventLabel: 'High',
                eventAction: 'What are the risk ratings',
            },
        ],
        riskGroup: [
            {
                level: 'Low',
                content:
                    'The most cautious approach to investing for a short time (up to a year), usually before taking your money.',
            },
            {
                level: 'Low-Medium',
                content: 'A very cautious approach to investing for the short term (up to two years).',
            },
            {
                level: 'Medium',
                content: 'A cautious approach to investing for the medium term (three to five years).',
            },
            {
                level: 'Medium-High',
                content: 'A balanced approach to investing for the medium term (three to five years).',
            },
            {
                level: 'High',
                content: 'A more adventurous approach to investing towards the long term (five to ten years).',
            },
        ],
        heading: 'How to assess your attitude to risk',
        content: 'This questionnaire can help you understand your attitude to risk but there are some limitations:',
        list: [
            {
                content:
                    'The questionnaire is designed to help you decide for yourself and shouldn’t be taken as advice',
            },
            {
                content: `The questionnaire doesn't consider your age, your financial goals or how much money you'd be comfortable losing i.e. your capacity for loss.`,
            },
            {
                content: `You should also consider how long you plan to invest for and when you think you might need access to your money.`,
            },
        ],
        contentTwo: `If you're not sure how to invest, it may be worth speaking to your financial adviser. If you don't have an adviser, you can find a `,
        linkText: 'list of regulated advisers here.',
        linkUrl: 'https://brokersireland.ie/ ',
        contentThree: 'You may be charged for this advice.',
    },
    attitudeRisk: {
        title: 'Take our questionnaire',
        content:
            'The attitude to risk questionnaire contains 13 multiple choice questions which you need to answer to discover your category.',
        heading4: 'It should take less than 5 minutes.',
        alertHeading: `We don't store your data`,
        alertContent: `You don't need to tell us anything about yourself, just how you would react to or feel in certain
        scenarios. We won't store any of this information in a way that can be linked back to you.`,
        buttonText: `I'm ready to start`,
    },
};

export const resultsContent = {
    title: 'Your risk level is',
    buttonText: 'Retake the test',
    heading: 'Next Steps',
    sustainabilityResultText: 'Your attitude to sustainability',

    cards: [
        {
            id: 'downloadPDF',
            icon: IconDownload,
            iconAltText: 'download icon',
            title: 'Download the results and email to your adviser',
            content: `Save the report results to your computer and send as an attachment to your adviser's email address.`,
            buttonText: 'Download PDF',
        },
    ],
};

export const SustainableInvestingContent = {
    title: 'Assessing your sustainable preferences',
    subheading: 'What do we mean by "sustainable preferences"?',
    textOne:
        'They describe your decision as to whether and to what extent, aspects of sustainability should be included in your investment decision. Sustainable investment preferences are divided into three categories:',
    list: [
        'Sustainable Investments',
        'Environmentally Sustainable Investments',
        'Investments that consider the negative impacts of the investment strategy on ESG factors',
    ],

    accordionTitleOne: 'What do you mean by “sustainable or ESG Investing”?',
    accordionContentOne: '',

    accordionListOne: [
        'Investments that are environmentally related are sustainable e.g. climate protection, water protection, forest protection, biodiversity) and/or address social criteria (e.g. fair pay, fair working conditions, fair supply chains) and follow good corporate governance (e.g. fair tax payments, no corruption)',
        'Sustainable Investing means investing in companies that contribute to an environmental or social objective while ensuring that the investment does not significantly harm any other environmental or societal objective under the EU Taxonomy. It also requires companies to follow good governance practices',
        'You may also come across the term “ESG”. It refers to the environmental, social, and governance factors used to evaluate companies and funds during the investment process',
        'ESG investing can be achieved by applying exclusions, limiting ESG risk, seeking ESG opportunities, practicing active ownership, targeting sustainability themes, or assessing impact',
    ],

    accordionTitleTwo: 'How does "sustainability" affect performance?',
    accordionContentTwo: 'The performance of an investment depends on many factors.',

    accordionListTwo: [
        'Sustainability is at infancy stage in terms of performance. No statement can be made as to whether an ESG investment will deliver better, the same or worse results in the medium term than a	comparable "non-ESG investment". In addition, past performance is no guarantee of future performance.',
        `Applying ESG and sustainability criteria in the investment process may result in the exclusion of	securities in which a Fund might otherwise invest. Such securities could be part of the benchmark against which the Fund is managed or be within the universe of potential investments. This may have a positive or negative impact on performance and may mean that the Fund's performance	profile differs to that of funds which are managed against the same benchmark or invest in a similar universe of potential investments but without applying ESG or sustainability criteria.`,
    ],

    question: 'Would you like to know your sustainable preferences?',
    buttonOne: 'Skip this section',
    buttonTwo: 'Go to questions ',
};
