import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'state';

export const useHasRiskQuestions = () => {
    const { state } = useAppContext();

    const navigate = useNavigate();

    useEffect(() => {
        if (!state.questions.length) {
            navigate('/');
        }
    }, [state, navigate]);
};
