import styled from 'styled-components';
import { breakpointUp } from '../../helpers/breakpoints';

import { Row as RBRow, Container as RBContainer } from 'react-bootstrap';

export const Container = styled(RBContainer)`
    ${breakpointUp.lg`
        width: 765px;
    `}
`;

export const Row = styled(RBRow)`
    .col-mobile-bottom-margin {
        margin-bottom: 32px;

        ${breakpointUp.lg`
            margin-bottom: 0;
        `}
    }

    .d-lg-flex {
        display: flex;
        flex-direction: column-reverse;
        ${breakpointUp.md`
            flex-direction: row;
        `}

        a:last-child {
            margin-bottom: 16px;
        }
    }
`;
