import styled from 'styled-components';
import {
    IonAccordion as IonicAccordion,
    IonAccordionGroup as IonicAccordionGroup,
    IonItem as IonicItem,
    IonLabel as IonicLabel,
} from '@ionic/react';

export const Accordion = styled(IonicAccordion)`
    div[slot='content'] {
        font-size: 16px;
        line-height: 24px;
    }

    [slot='end'] {
        margin-right: -10px;
    }
`;

export const AccordionGroup = styled(IonicAccordionGroup)``;

export const AccordionItem = styled(IonicItem)`
    --ripple-color: transparent;
    --ion-color-base: transparent !important;
    --background-hover-opacity: 0 !important;
    --ion-color-contrast: ${({ theme }) => theme.colors.primaryText} !important;
    margin-bottom: 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder}!important;
    overflow: unset;
`;

export const AccordionLabel = styled(IonicLabel)`
    white-space: unset !important;
    margin-left: -16px;
    overflow: unset;

    div {
        display: flex;
        gap: 10px;
        align-items: center;
    }
`;
