import { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { logAnalyticsEvent } from 'services/analytics';

import { SET_CURRENT_ESG_QUESTION, SET_ESG_QUESTIONS, UPDATE_ESG_ANSWERS, useAppContext } from '../../state';
import { questionsNew } from '../../data/esgQuestionsData';

export const useQuestionForm = () => {
    const { state, dispatch } = useAppContext();
    const navigate = useNavigate();
    const isLastQuestion = state?.currentESGQuestion === state?.esgQuestions.length;

    useEffect(() => {
        if (state?.esgQuestions.length && state?.currentESGQuestion) {
            console.log('calling esg questions');
            logAnalyticsEvent({
                action: `Assessing your sustainable preferences Q${state.currentESGQuestion}`,
                label: `Display`,
            });
        }
    }, [state.esgQuestions, state.currentESGQuestion]);

    useEffect(() => {
        async function fetchQuestionsData() {
            try {
                const questions = await questionsNew;

                dispatch({ type: SET_ESG_QUESTIONS, questions });
            } catch {
                navigate('/error');
            }
        }
        fetchQuestionsData();
    }, [dispatch, navigate]);

    useEffect(() => {
        if (state.esgAnswers.length > 0 && state.esgAnswers.length === state.esgQuestions.length) {
            navigate('/results');
        }
    }, [state, navigate]);

    const question = state?.esgQuestions ? state.esgQuestions[state.currentESGQuestion - 1] : null;

    const { handleSubmit, register, formState, reset, watch } = useForm();
    const onSubmit = useCallback(
        async ({ esganswer, esgvalue }: any) => {
            reset();

            const esgValue = esganswer === 'Yes' ? esgvalue : '';
            console.log('esgValue', esgValue);
            dispatch({
                type: UPDATE_ESG_ANSWERS,
                answer: {
                    questionId: parseInt(state.currentESGQuestion, 10),
                    answers: [
                        {
                            answer: esganswer,
                            value: esgValue,
                        },
                    ],
                },
            });
            dispatch({
                type: SET_CURRENT_ESG_QUESTION,
                currentQuestion: Math.min(state.currentESGQuestion + 1, state.esgQuestions.length),
            });
        },
        [state, dispatch, reset]
    );

    const buttonLabel = isLastQuestion ? 'Submit' : 'Next question';

    return {
        handleSubmit,
        register,
        formState,
        question,
        buttonLabel,
        onSubmit,
        watch,
    };
};
